import {createApp} from 'vue';
import App from './App.vue'
import router from './router'
import i18n from "@/i18n";
//@ts-ignore
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faArrowRightFromBracket, faCamera, faKey, faMask, faNetworkWired, faPlus,
    faUser, faUsers, faTrash, faPen, faPencil, faCopy, faEye, faPhoneFlip
} from '@fortawesome/free-solid-svg-icons'
import {createPinia} from 'pinia';
import {authStore} from "@/store/auth";
import '@/assets/styles/app.scss'
import {library} from "@fortawesome/fontawesome-svg-core";
import PrimeVue from 'primevue/config';
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Fieldset from "primevue/fieldset";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import Password from "primevue/password";
import ProgressSpinner from "primevue/progressspinner";
import Textarea from "primevue/textarea";
import InputMask from "primevue/inputmask";
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import MultiSelect from "primevue/multiselect";
import ConfirmPopup from "primevue/confirmpopup";
import "primevue/resources/themes/lara-light-teal/theme.css";
import 'primeflex/primeflex.scss'
import 'cropperjs/src/css/cropper.scss'
import 'primeicons/primeicons.css'
import Toast from "primevue/toast";
import {appStore} from "@/store/app";
import Avatar from "primevue/avatar";

library.add(
    faArrowRightFromBracket,
    faUser,
    faUsers,
    faNetworkWired,
    faMask,
    faKey,
    faCamera,
    faPlus,
    faTrash,
    faPen,
    faPencil,
    faCopy,
    faCamera,
    faEye,
    faPhoneFlip
)

const app = createApp(App)
app.use(router)
    .use(createPinia())
    .use(i18n)
    .use(PrimeVue)
    .use(ToastService)
    .use(ConfirmationService)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component("PrimeButton", Button)
    .component("DataTable", DataTable)
    .component("Column", Column)
    .component("Accordion", Accordion)
    .component("AccordionTab", AccordionTab)
    .component("Fieldset", Fieldset)
    .component("Toast", Toast)
    .component("Dialog", Dialog)
    .component("InputText", InputText)
    .component("Textarea", Textarea)
    .component("InputSwitch", InputSwitch)
    .component("InputMask", InputMask)
    .component("Password", Password)
    .component("MultiSelect", MultiSelect)
    .component("ProgressSpinner", ProgressSpinner)
    .component("ConfirmPopup", ConfirmPopup)
    .component("Avatar", Avatar)
    .mount('#app')


// navigator.geolocation.getCurrentPosition(position => {
//     console.log(position.coords.latitude, position.coords.longitude)
// })

authStore().initJwt()
appStore().initCollapsion()
