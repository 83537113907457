import {createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw} from 'vue-router'
import {authMiddleware, MiddlewareArgs, permissionMiddleware} from "@/middleware";
import {Permissions} from "@/types/permissions";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('../layouts/DashboardLayout.vue'),
        meta: {
            middlewares: [authMiddleware, permissionMiddleware]
        },
        children: [
            {
                path: '/',
                name: 'my-profile',
                component: () => import('../views/MyProfile.vue'),
            },
            {
                path: '/users',
                name: 'users',
                component: () => import('../views/users/Index.vue'),
                meta: {
                    permissions: Permissions.sso_users_index
                }
            },
            {
                path: '/users/:id',
                name: 'users.show',
                component: () => import('../views/users/Show.vue'),
                meta: {
                    permissions: Permissions.sso_users_show
                }
            },
            {
                path: '/users/create',
                name: 'users.create',
                component: () => import('../views/users/Create.vue'),
                meta: {
                    permissions: Permissions.sso_users_create
                }
            },
            {
                path: '/users/:id/update',
                name: 'users.update',
                component: () => import('../views/users/Update.vue'),
                meta: {
                    permissions: Permissions.sso_users_update
                }
            },
            {
                path: '/clients',
                name: 'clients.index',
                component: () => import('../views/clients/Index.vue'),
                meta: {
                    permissions: Permissions.sso_clients_index
                }
            },
            {
                path: '/roles',
                name: 'roles.index',
                component: () => import('../views/roles/Index.vue'),
                meta: {
                    permissions: Permissions.sso_roles_index
                }
            },
            {
                path: '/permissions',
                name: 'permissions.index',
                component: () => import('../views/permissions/Index.vue'),
                meta: {
                    permissions: Permissions.sso_permissions_index
                }
            }
        ]
    },
    {
        path: '/auth',
        component: () => import('../layouts/AuthLayout.vue'),
        children: [
            {
                path: '/auth/login',
                name: 'auth.login',
                component: () => import('../views/auth/Login.vue'),
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), routes
})

function nextFactory(context: MiddlewareArgs, middlewares: Function[], index: number): Function {
    const nextMiddleware = middlewares[index];

    if (!nextMiddleware) {
        return context.next;
    }
    const subsequentMiddleware = nextFactory(context, middlewares, index + 1);
    return nextMiddleware({...context, next: subsequentMiddleware})
}

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) => {
    if (!to.meta.middlewares) {
        return next()
    }

    let index = 0;
    const middlewares: Function[] = Array.isArray(to.meta.middlewares) ? to.meta.middlewares : [to.meta.middlewares]
    let permissions: string[] | string | unknown = to.meta.permissions

    const context: MiddlewareArgs = {
        to, from, next, permissions
    };

    return middlewares[index]({...context, next: nextFactory(context, middlewares, index + 1)});
})

export default router
