import {RouteLocationNormalized} from "vue-router";
import {authStore} from "@/store/auth";
import {appStore} from "@/store/app";
import router from "@/router";

export interface MiddlewareArgs {
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: Function,
    permissions: string[] | string | unknown
}

export const authMiddleware = async (params: MiddlewareArgs) => {
    const auth = authStore()
    if (await auth.isAuthed()) {
        return params.next()
    }
    await auth.logout()
    return router.push({
        name: 'auth.login'
    })
}

export const guestMiddleware = async (params: MiddlewareArgs) => {
    const auth = authStore()
    if (await auth.isAuthed()) {
        return params.next(params.from)
    }
    return params.next()
}

export const permissionMiddleware = async (params: MiddlewareArgs) => {
    const app = appStore()
    app.deactivate403()
    if (!params.permissions) {
        return params.next()
    }

    const auth = authStore()

    if (Array.isArray(params.permissions)) {
        let hasAllPermissions: boolean = true
        for (let p in params.permissions) {
            hasAllPermissions = auth.hasPermission(params.permissions[p])
            if (!hasAllPermissions) {
                app.activate403()
                break
            }
        }
    } else {
        app._403 = !auth.hasPermission(params.permissions as string)
    }

    return params.next()
}