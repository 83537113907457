export default {
    'add': 'Добавить',
    'login_via_sso': 'Войти через SSO',
    'return_to_login_page': 'Вернуться на страницу входу',
    'something_went_wrong': 'Упс! Что то пошло не так!',
    'questionnaire_system': 'Система опросников',
    'global_information': 'Общая информация',
    'questionnaire': 'Опросник | Опросники',
    'create_questionnaire': 'Создать новый опросник',
    'questionnaire_response': 'Ответ на вопросы | Ответы на вопросы',
    'create': 'Создать',
    'save': 'Сохранить',
    'finish_later': 'Закончик позже',
    'question_type': 'Тип вопроса',
    'select_question_type': 'Выберите тип вопроса',
    'input_type': 'Тип поля',
    'select_input_type': 'Выберите тип поля',
    'answer_type': 'Тип ответа',
    'select_answer_type': 'Выберите тип ответа',
    'question_title': 'Вопрос',
    'select_language': 'Выберите язык',
    'ru': 'Русский',
    'uz': 'O\'zbekcha',
    'uz_cyrl': 'Ўзбекча',
    'add_language': 'Добавить язык',
    'url': 'Ссылка',
    'title': 'Титул',
    'name': 'Название',
    'description': 'Описание',
    'add_input': 'Добавить поле',
    'answers': 'Ответы',
    'search': 'Поиск',
    'date': 'Дата',
    'from': 'От',
    'until': 'До',
    'activity': 'Действия',
    'required_field': 'обязательное поле',
    'repeats': 'Повторы',
    'prefix': 'Префикс',
    'actions': 'Действия',
    'permissions': 'Разрешения',
    'permission': 'Разрешение',
    'permission_list': 'Список разрешений',
    'no_permissions_found': 'Разрешений не найдено.',
    'create_permission': 'Создать разрешение',
    'edit_permission': 'Редактировать разрешение',
    'roles': 'Роли',
    'role': 'Роль',
    'no_roles_found': 'Роли не найдены.',
    'create_role': 'Создать роль',
    'edit_role': 'Редактировать роль',
    'my_profile': 'Мой профиль',
    'users': 'Пользователи',
    'user': 'Пользователь',
    'no_users_found': 'Пользователи не найдены',
    'clients': 'Клиенты',
    'client': 'Клиент',
    'no_clients_found': 'Клиенты не найдены.',
    'username': 'Имя пользователя',
    'password': 'Пароль',
    'first_name': 'Имя',
    'last_name': 'Фамилия',
    'phone': 'Номер телефона',
    'update': 'Обновить',
    'cancel': 'Отменить',
    'reset': 'Сброс',
    'authorization': 'Авторизация',
    'login': 'Войти',
    'profile': 'Профиль',
    'current_password': 'Текущий пароль',
    'new_password': 'Новый пароль',
    'confirm_password': 'Подтвердите пароль',
    'client_id': 'Идентификатор клиента',
    'client_secret': 'Секретный ключ клиента',
    'create_client': 'Создать нового клиента',
    'update_client': 'Редактировать клиента',
    'redirect_uri': 'Адрес переадресации',
    'origin': 'Источник',
    'create_user': 'Создать нового пользователя',
    'choose_photo': 'Выберите фото',
    'profile_image': 'Фото профиля',
    'crop': 'Обрезать',
    'full_name': 'Полное имя',
    'really_wanna_delete': 'Вы действительно хотите удалить?',
    'successfully_deleted': 'Успешно удалено',
    'name_should_be_unique': 'Имя должно быть уникальным',
    'should_be_uri': 'должен быть адресом (uri)',
    'update_user': 'Редактирование пользователя',
    'insufficiently_authorized_person': 'Недостаточно уполномоченное лицо',
    'page_not_found': 'Страница не найдена',
    'photo': 'Фото',
    'fill_in_username': 'Заполните Имя пользователя',
    'such_username_exists': 'Такое имя пользователя уже существует',
    'fill_in_first_name': 'Заполните Имя',
    'fill_in_last_name': 'Заполните Фамилию',
    'fill_in_phone': 'Заполните Телефон',
    'select_roles': 'Выберите роль или роли',
    'passwords_not_matched': 'Пароль не подтверждён'
}