export enum Permissions {
    sso_users_index = "sso_users_index",
    sso_users_show = "sso_users_show",
    sso_users_create = "sso_users_create",
    sso_users_update = "sso_users_update",
    sso_users_delete = "sso_users_delete",
    sso_clients_index = "sso_clients_index",
    sso_clients_show = "sso_clients_show",
    sso_clients_create = "sso_clients_create",
    sso_clients_update = "sso_clients_update",
    sso_clients_delete = "sso_clients_delete",
    sso_roles_index = "sso_roles_index",
    sso_roles_show = "sso_roles_show",
    sso_roles_create = "sso_roles_create",
    sso_roles_update = "sso_roles_update",
    sso_roles_delete = "sso_roles_delete",
    sso_permissions_index = "sso_permissions_index",
    sso_permissions_show = "sso_permissions_show",
    sso_permissions_create = "sso_permissions_create",
    sso_permissions_update = "sso_permissions_update",
    sso_permissions_delete = "sso_permissions_delete",
}