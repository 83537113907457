import {defineStore} from "pinia";
import jwtDecode from "jwt-decode";
import UserProfile from "@/components/UserProfile.vue";
import {http} from "@/store/app";
import {RawAxiosRequestHeaders} from "axios";
import {User} from "@/store/user";

export const GrantTypeCode = "code",
    GrantTypePassword = "password",
    GrantTypeAuthorizationCode = "authorization_code",
    GrantTypeRefreshToken = "refresh_token"

export interface AuthResponse {
    type: string
    access_token: string,
    refresh_token: string,
    expires_at: number,
}

export interface InitJwtArgs {
    access_token: string,
    refresh_token: string,
    expires_in: number,
    type: string
}

export interface LoginCredentials {
    username: string,
    password: string,

    grant_type: string | null,
    client_id: string | null,
    redirect_uri: string | null,
    state: string | null,
    code_challenge: string | null,
}

export interface JWTPayload {
    aud: string,
    iat: number,
    nbf: number,
    exp: number,
    sub: string,
    type: string,
    roles: string[] | null,
    permissions: string[] | null
}

export interface UserProfileUpdate {
    id: string
    username: string
    first_name: string
    last_name: string
    phone: string
    image: string
    imageOriginal: string
    imageUri: string
    imageUpdated: boolean
    current_password: string
    new_password: string
    confirm_password: string
    roles: string[]
}

export const authStore = defineStore('auth', {
    state: () => ({
        access_token: '',
        refresh_token: '',
        expires_at: 0,
        type: '',
        payload: {
            aud: '',
            iat: 0,
            nbf: 0,
            exp: 0,
            sub: '',
            user_info: null,
            type: '',
            permissions: [],
            roles: [],
        } as JWTPayload
    }),
    actions: {
        async initJwt(params?: AuthResponse | null) {
            let localCred = localStorage.getItem('sso.sanitation.uz')
            if (!params && !localCred) {
                return false
            }

            if (params) {
                localStorage.setItem('sso.sanitation.uz', JSON.stringify(params))
            } else {
                params = JSON.parse(localCred!)
            }

            this.access_token = params!.access_token
            this.refresh_token = params!.refresh_token
            this.expires_at = params!.expires_at
            this.type = params!.type

            this.payload = jwtDecode(params!.access_token);

            if (!await this.isAuthed(params)) {
                try {
                    this.logout()
                } catch(err) {
                    console.log(err)
                }
                return false
            }

            return true
        },
        async isAuthed(params?: AuthResponse | null) {
            if (!params?.access_token) {
                params = {
                    access_token: this.access_token,
                    refresh_token: this.refresh_token,
                    expires_at: this.expires_at,
                    type: this.type
                }
            }
            if (!Boolean(params.access_token) && !Boolean(this.access_token)) {
                return false
            }

            let authed: boolean = true

            switch (true) {
                case Boolean(!params.access_token):
                    authed = false
                break
                case this.isExpired():
                    authed = false
                break
                case Date.now() >= params.expires_at * 1000:
                    authed = false
                break
            }

            return authed
        },
        refreshCredentials(params?: AuthResponse | null): boolean {
            return false
        },
        async getProfile() {
            try {
                const response = await http.get("api/v1/profile", {
                    headers: this.Authorization()
                })

                return response
            } catch (err) {
                return err
            }
        },
        updateProfile(data: UserProfileUpdate) {

        },
        logout() {
            try {
                http.post('api/v1/logout', {}, {
                    headers: this.Authorization()
                })
            } catch (err) {
            }
            this.clearState()
            this.clearStorage()
        },
        isExpired(): boolean {
            return false
        },
        clearState(): boolean {
            this.access_token = ''
            this.refresh_token = ''
            this.expires_at = 0
            this.type = ''
            this.payload = {
                aud: '', iat: 0, nbf: 0, exp: 0, sub: '', permissions: [], roles: [], type: ''
            }
            return true
        },
        clearStorage(): boolean {
            localStorage.removeItem('sso.sanitation.uz')
            return true
        },
        hasPermission(name: string): boolean {
            return Boolean(this.payload.permissions?.includes(name))
        },
        Authorization(): RawAxiosRequestHeaders {
            return {
                Authorization: `${this.type} ${this.access_token}`
            }
        },
    }
})