import { defineStore } from "pinia";
import axios, {AxiosError} from "axios";
import {useToast} from "primevue/usetoast";
import {authStore} from "@/store/auth";

export const http = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URI,
    timeout: 1000,
    withCredentials: true
})

export const appStore = defineStore('app', {
    state: () => ({
        sidebarCollapse: false as boolean,
        _403: false,
        _404: false,
        backHost: process.env.VUE_APP_BACKEND_URI,
        toast: useToast(),
        auth: authStore()
    }),
    actions: {
        collapse: function () {
            this.sidebarCollapse = !this.sidebarCollapse
            localStorage.setItem('collapse', this.sidebarCollapse.toString())
        },
        initCollapsion: function () {
            const collapse = localStorage.getItem('collapse')
            if (collapse && collapse === 'true') {
                this.collapse()
            }
        },
        collapseWhileScreenChanging: function () {
            const shouldCollapse = window.innerWidth < 1500
            localStorage.setItem('collapse', shouldCollapse.toString())
            this.sidebarCollapse = shouldCollapse
        },
        activate403(): void {
            this._403 = true
        },
        deactivate403(): void {
            this._403 = false
        },
        activate404(): void {
            this._404 = true
        },
        deactivate404(): void {
            this._404 = false
        },
        async checkAxiosResult(result: AxiosError | SyntaxError) {
            if (result instanceof AxiosError) {
                if (!result?.response?.status) {
                    this.toast.add({
                        severity: 'error',
                        summary: result.name,
                        detail: result.message,
                        life: 2000
                    })
                } else {
                    switch (result.response.status) {
                        case 400:
                            //@ts-ignore
                            if (result.response?.data?.error) {
                                this.toast.add({
                                    severity: 'warn',
                                    summary: 'Error',
                                    //@ts-ignore
                                    detail: result.response.data.error,
                                    life: 2000
                                })
                            }
                        break
                        case 401:
                            await this.auth.logout()
                            break
                        case 403:
                            this.activate403()
                            break
                        case 404:
                            this.activate404()
                            break
                    }
                }
            } else {
                this.toast.add({
                    severity: 'error',
                    summary: result.name,
                    detail: result.message,
                    life: 2000
                })
            }
        }
    }
})