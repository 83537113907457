import {createI18n} from "vue-i18n";
import ru from '@/locales/ru'
import uz from '@/locales/uz'
import uz_cyrl from '@/locales/uz_cyrl'

let l: string = localStorage.getItem("question-sheet-language") ?? "ru"

export default createI18n({
    locale: l,
    legacy: false,
    messages: {
        ru, uz, uz_cyrl
    },
    globalInjection: true
})